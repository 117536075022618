import {
  Box,
  CircularProgress,
  Icon,
  IconButton,
  List,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';
import { updateStatusSeen } from 'apis/notification.api';
import EmptyImage from 'assets/images/empty-message.svg';
import SuiBox from 'components/SuiBox';
import { firestore } from 'config/firebase';
import NotificationItem from 'examples/Items/NotificationItem';
import {
  DocumentData,
  collection,
  documentId,
  getCountFromServer,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCollection,
  useCollectionData,
} from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import {
  IsSeenEnum,
  Notification,
  NotificationStatus,
} from 'shared/models/notification.model';
import { delay } from 'lodash-es';
import NotificationsToolbar from './NotificationsToolbar';

interface NotificationProps {
  userUid?: string;
  lighTheme?: boolean;
  className?: string;
}

export default function NotificationButton({
  userUid,
  className,
  lighTheme,
}: NotificationProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const [notificationsList, setNotificationsList] = useState<Notification[]>(
    []
  );

  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [countNewStatusItems, setCountNewStatusItems] = useState(0);

  const memoizedQueries = useMemo(() => {
    const baseQuery = collection(
      firestore,
      `notifications/${userUid}/contents`
    );
    return query(
      baseQuery,
      where('status', '==', NotificationStatus.NEW),
      orderBy(documentId(), 'desc'),
      limit(pageSize)
    ).withConverter<Notification, DocumentData>({
      toFirestore: (data): DocumentData => data as DocumentData,
      fromFirestore: (snapshot): Notification =>
        snapshot.data() as Notification,
    });
  }, [pageSize, userUid]);


  useEffect(() => {
    if (isLoading) return; // Ngăn chặn truy vấn không cần thiết
    const getCountUnseen = async () => {
      const coll = collection(firestore, `notifications/${userUid}/contents`);
      const q = query(coll, where('isSeen', '==', IsSeenEnum.NotSeen), where('status', '==', NotificationStatus.NEW));
      return getCountFromServer(q);
    }

    const countUnseen = async () => {
      const count = await getCountUnseen();
      setCountNewStatusItems(count.data().count);
    };
    countUnseen()

  }, [userUid, isLoading]);


  const [notifications] = useCollectionData<Notification>(memoizedQueries);

  useEffect(() => {
    if (notifications) {
      setNotificationsList(notifications);
    }
  }, [notifications, pageSize]);

  const handleCloseMenu = useCallback(() => {
    const notifyListId = notifications?.map((notifiItems) => notifiItems.id);
    updateStatusSeen(notifyListId || []);
    setMenuAnchor(null);
    setPageSize(10);
  }, [notifications]);

  const handleNotification = useCallback(
    (bookingId = '', notifyId = '') => {
      updateStatusSeen([notifyId]);
      navigate(`/schedules/${bookingId}`);
    },
    [navigate]
  );

  const [selectedNotification, setSelectedNotification] = useState<string[]>(
    []
  );

  const toggleSelected = useCallback((id: string) => {
    setSelectedNotification((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      }

      return [...prev, id];
    });
  }, []);

  const toggleAll = useCallback(
    () =>
      setSelectedNotification((prev) =>
        prev.length === notificationsList.length
          ? []
          : notificationsList.map((item) => item.id)
      ),
    [notificationsList]
  );

  const handleWaypointEnter = useCallback(async () => {
    if (isLoading || notificationsList.length < pageSize) return; // Ngăn chặn truy vấn không cần thiết
    setIsLoading(true);

    try {
      await new Promise<void>((resolve) => {
        delay(() => {
          setPageSize((prevPageSize) => prevPageSize + 10);
          resolve();
        }, 500);
      });
    } finally {
      setIsLoading(false);
    }
  }, [isLoading, notificationsList.length, pageSize]);

  if (!notificationsList) return null;

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        className={className}
        aria-controls="notification-menu"
        aria-haspopup="true"
        onClick={(event) => setMenuAnchor(event.currentTarget)}
      >
        <Icon className={lighTheme ? 'text-white' : 'text-dark'}>
          notifications
        </Icon>
        {!!countNewStatusItems && (
          <SuiBox
            sx={{
              backgroundColor: `${theme.palette.error.main} !important`,
              color: `${theme.palette.white.main} !important`,
              position: 'absolute',
              top: '-3px',
              right: '0',
              fontSize: '0.65rem',
              fontWeight: 500,
              borderRadius: '999px',
              height: '18px',
              minWidth: '18px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px 6px',
              placeContent: 'center',
              transform: 'scale(1) translate(50%, -50%)',
              transformOrigin: '100% 0%',
            }}
          >
            {countNewStatusItems}
          </SuiBox>
        )}
      </IconButton>

      <Popover
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={Boolean(menuAnchor)}
        onClose={handleCloseMenu}
        sx={{
          marginTop: '1rem',
        }}
        PaperProps={{
          sx: {
            maxWidth: 540,
            position: 'relative',
            p: 0,
            backgroundColor: 'white !important',
            overflow: 'hidden',
            m: 1,
          },
        }}
      >
        <List sx={{ maxHeight: theme.spacing(60), overflow: 'auto', p: 1 }}>
          {notificationsList.length ? (
            notificationsList.map((notiItem, index) => (
              <NotificationItem
                key={index}
                notificationData={notiItem}
                onClick={() => {
                  handleNotification(notiItem.bookingId, notiItem.id);
                  setMenuAnchor(null);
                }}
                selected={selectedNotification.includes(notiItem.id)}
                toggleSelected={toggleSelected}
              />
            ))
          ) : (
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                p: 2,
                minWidth: 300,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box src={EmptyImage} component="img" mb={3} />
              <Typography fontWeight={500} fontSize={16}>
                Bạn chưa có thông báo nào!
              </Typography>
            </SuiBox>
          )}
          {isLoading && notificationsList.length && (
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ p: 2 }}
            >
              <CircularProgress />
            </SuiBox>
          )}
          <Waypoint onEnter={handleWaypointEnter} />
        </List>
        <NotificationsToolbar
          toggleAll={toggleAll}
          resetSelectedNotification={() => setSelectedNotification([])}
          selectedNotification={selectedNotification}
          isAllSelected={
            selectedNotification.length === notificationsList.length
          }
        />
      </Popover>
    </>
  );
}
